import React from "react";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router-dom";
import campaignService from "../services";
import Report from "./Report";
import axios from "axios";
import Notifications from "../../utils/notifications";




class CampaignHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            changingStatusID:null,
            changingStatusID2: null,
            participantsReportID: null,
            deletingCampaignID:null
        };
    }

    getCampaignList = () => {
        campaignService.getCampaigns()
            .then(
                (response) => {
                    const sorted=response.data.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
                    this.setState({
                        campaigns: [...sorted]
                    })
                    this.setState({deletingCampaignID:null});
                },
                (error) => {
                    //this.getCampaignList()
                }
            );
    }

    makeCampaignActive = (id, status) => {
        this.setState({changingStatusID:id});
        campaignService.makeCampaignActive(id, status)
            .then(
                (response) => {
                    this.getCampaignList();
                    this.setState({changingStatusID:null});
                },
                (error) => {
                    this.setState({changingStatusID:null})
                }
            );
    }

    allowDuplicateContact = (id, status) => {
        this.setState({changingStatusID2:id});
        campaignService.allowDuplicateContact(id, status)
            .then(
                (response) => {
                    this.getCampaignList();
                    this.setState({changingStatusID2:null});
                },
                (error) => {
                    this.setState({changingStatusID2:null})
                }
            );
    }

    deleteCampaign=(id)=>{
        if(window.confirm(`Are you sure that you want to delete Campaign ${id} ?`)){

            this.setState({deletingCampaignID:id})
            campaignService.deleteCampaign(id)
            .then(
                (response) => {
                    this.getCampaignList();
                    
                },
                (error) => {
                    this.setState({changingStatusID:null})
                    this.setState({changingStatusID2:null})
                    this.setState({deletingCampaignID:null});
                }
            );

        }
        
    }

    onPressNewCampaign=()=>{
        this.props.history.push("/new");
    }

    componentDidMount() {
        this.getCampaignList();
    }

    onClickParticipantReport = (id) => {
        this.setState({
            participantsReportID: id
        });

        const authToken = localStorage.getItem("token");
        axios.get(
            `${process.env.REACT_APP_API_URL}/campaigns/api/v1/${id}/participant_report`,
            {
                headers: { Authorization: "Bearer " + authToken },
                responseType: "blob",
            }
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            //console.log(url)
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${id}_participant_report.csv`);
            document.body.appendChild(link);
            link.click();
            this.setState({ participantsReportID: null});
        }).catch((err) => {
            this.setState({ participantsReportID: null});
            Notifications.error("Something went wrong !");
        });
    }



    render() {
        return (
            <div>
                <Button variant="outline-dark" onClick={this.onPressNewCampaign} style={{marginBottom:10}}> ✍️ Make New Campaign</Button>
                {/*<h1></h1>*/}
                <table className={"table table-bordered"}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th colspan={"2"}>Report</th>
                            <th colSpan={"2"}>Actions</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.campaigns.map(({id, name, is_active, created, allow_duplicate_contact}, index) => {
                        return (
                            <tr key={"campaign-" + index} style={{opacity:this.state.deletingCampaignID===id?.3:1}}>
                                <td>{id}</td>
                                <td>{name}</td>
                                <td><h5><Badge variant={is_active?"success":"secondary"}>{is_active ? 'Active' : 'Inactive'}</Badge></h5></td>
                                <td style={{fontSize:13}}>{new Date(created).toUTCString()}</td>
                                <td>
                                    <Report id={id} created={new Date(created)}/>
                                </td>
                                <td>
                                    <Button 
                                        size="sm"
                                        onClick={(event) => {this.onClickParticipantReport(id)}}
                                        variant={"outline-dark"}
                                        disabled={this.state.participantsReportID!==null?true:false}>
                                            {this.state.participantsReportID===id && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            Participants Report
                                    </Button>
                                </td>
                                <td><Button 
                                        size="sm"
                                        onClick={(event) => this.makeCampaignActive(id, !is_active)}
                                        variant={!is_active?"outline-dark":"outline-success"}
                                        disabled={this.state.changingStatusID!==null?true:false}>
                                            {this.state.changingStatusID===id && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            Make {is_active ? 'Inactive' : 'Active' }
                                        
                                    </Button>
                                </td>
                                <td>
                                <Button 
                                        size="sm"
                                        onClick={(event) => this.allowDuplicateContact(id, !allow_duplicate_contact)}
                                        variant={!allow_duplicate_contact?"outline-dark":"outline-success"}
                                        disabled={this.state.changingStatusID2!==null?true:false}>
                                            {this.state.changingStatusID2===id && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            {allow_duplicate_contact ? 'No duplicate contact' : 'Allow duplicate contact' }
                                        
                                    </Button>
                                </td>
                                <td>
                                    <Button variant="outline-danger" size="sm" onClick={()=>this.deleteCampaign(id)}  >
                                        ✕    
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>

                </table>


            </div>
        )
    }
}

export default withRouter(CampaignHome);

