import api from "../../utils/network";

const campaignService = {
    createCampaign: function (data) {
        return api.post("/campaigns/api/v1/create", data);
    },
    getCampaigns: function () {
        return api.get("/campaigns/api/v1/list");
    },
    deleteCampaign: function (id) {
        return api.delete(`/campaigns/api/v1/${id}`);
    },
    makeCampaignActive: function (id, status) {
        return api.put('/campaigns/api/v1/'+ id +'/make_active_inactive', {status: status});
    },
    allowDuplicateContact: function (id, status) {
        return api.put('/campaigns/api/v1/'+ id +'/allow_disallow_duplicate_contact', {status: status});
    },
}

export default campaignService;