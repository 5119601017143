import React from "react";
import {withRouter} from 'react-router-dom';
import Question from "./Question";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Notifications from "../../utils/notifications";
import campaignService from "../services";

import Badge from 'react-bootstrap/Badge'

class NewCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting:false,
            campaign: {
                name: "",
                questions: [
                    {name: "", type: "", options: []},
                ]
            }
        }
    }

    onRemoveQuestions = (index) => {
        if(window.confirm(`Are you sure that you want to delete  Question#${index+1} ?`)){
            let questions = [...this.state.campaign.questions];
            questions.splice(index, 1);
            console.log(questions)
            this.setState({
                campaign: {
                    ...this.state.campaign, questions: [...questions]
                }
            })
        }

        else return;
        
    }

    onChangeCampaignName = (event) => {
        this.setState({
            campaign: {
                ...this.state.campaign, name: event.target.value
            }
        });
    }

    onChangeQuestion = (data, index) => {
        let questions = this.state.campaign.questions;
        questions[index] = data;
        this.setState({
            campaign: {
                ...this.state.campaign, questions: [...questions]
            }
        })
    }

    addNewQuestion = (event) => {
        // event.preventDefault();
        this.setState({
            campaign: {
                ...this.state.campaign,
                questions: [...this.state.campaign.questions, {name: "", type: "",options:[]}]
            }
        });
    }

    validateSchema = () => {
        let {campaign} = this.state;
        if (!campaign.name) {
            Notifications.error("Campaign name is required");
            return false;
        }
        if(campaign.questions.length === 0) {
            Notifications.error("Question count can not be zero");
            return false;
        }

        for(var i=0;i<campaign.questions.length;i++) {
            var question = campaign.questions[i];
            if(!question.name) {
                Notifications.error("Question #"+(i+1) + " has no name");
                return false;
            }
            if(!question.type) {
                Notifications.error("Question #"+(i+1) + " has no question type");
                return false;
            }

            if(question.type !== 'text' && question.options.length === 0) {
                Notifications.error("Question #"+(i+1) + " has no options");
                return false;
            }
        }
        return true;
    }

    createCampaign = (data) => {
        this.setState({submitting:true})
        campaignService.createCampaign(data)
            .then(
                (response) => {
                    Notifications.success("Campaign created successfully");
                    this.setState({submitting:false})
                    this.props.history.push('/');
                },
                (error) => {
                    this.setState({submitting:false})
                    Notifications.error("Something went wrong !")
                }
            )
    }

    onSubmit = (event) => {
        const validated = this.validateSchema();
        if(validated) {
            const {campaign} = this.state;
            this.createCampaign(campaign);
        }

    }

    render() {
        let questions = this.state.campaign.questions;
        return (
            <div className={"row col-6 offset-3"}>
                <div className={"row col-12"}>
                    <div className={"col-12 form-group"}>
                        <input className={"form-control"} type={"text"} value={this.state.campaign.name}
                               onChange={this.onChangeCampaignName} placeholder={"Campaign Name"}/>
                    </div>
                    <div className={"col-12 form-group"}>
                        {questions.map((question, index) => {
                            return <Question key={'question-' + index} onChangeState={this.onChangeQuestion}
                                             onRemoveQuestion={this.onRemoveQuestions} index={index}
                                             question={question}/>
                        })}
                    </div>
                    <div className={"col-12 form-group"}>
                        <button className={"btn btn-primary"} onClick={this.addNewQuestion}>Add New Question</button>
                        &nbsp;
                        <button className={"btn btn-primary"} onClick={this.onSubmit}>
                        {this.state.submitting && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                        />}
                            Submit</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewCampaign);