import NewCampaign from "../components/NewCampaign";
import CampaignHome from "../components/CampaignHome";

const campaignRoutes = [
    {
        path: '/',
        component: CampaignHome,
    },
    {
        path: '/new',
        component: NewCampaign
    }
];
export default campaignRoutes;