import api from "../../utils/network";

const authenticationService = {
    login: function (data) {
        return api.post('/api/token', data);
    },

    verifyToken: function (data) {
        return api.post('/api/token/verify', data);
    },
    getUserDetail: function () {
        return api.get('/core/api/v1/me');
    }
}

export default authenticationService;