import React from "react";
import {withRouter} from "react-router-dom";
import authenticationService from "../../authentication/services";
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

class Nav extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: null
        };
    }

    doTokenCheck = () => {
        // console.log(localStorage.getItem("token"));
        var token = localStorage.getItem("token");
        if(token) {
            authenticationService.verifyToken({token: localStorage.getItem("token")})
            .then(
                (response) => {
                    if(this.props.location.pathname ==='/auth/login') {
                        this.props.history.push('/');
                    }
                    this.getUserDetail();
                },
                (err) => {
                    localStorage.clear();
                    this.props.history.push('/auth/login');
                }
            );
        } else {
            this.setState({
                user: null
            });
            if(this.props.location.pathname !== '/auth/login') {
                this.props.history.push('/auth/login');
                return;
            }
        }
    }

    getUserDetail = () => {
        authenticationService.getUserDetail()
            .then(
                response => {
                    this.setState({
                        user: response.data
                    });
                },
                err => {

                }
            )
    }

    componentDidMount() {
        console.log(process.env.REACT_APP_API_URL);
        this.doTokenCheck();
        this.subscription = this.props.history.listen((nextPath, action) => {
            this.doTokenCheck();
        });
    }

    componentWillUnmount() {
        this.subscription();
    }

    onClickLogout = (e) => {
        localStorage.clear();
        this.props.history.push('/auth/login');
    }

    render() {
        // this.doTokenCheck();
        let username = '';
        if(this.state.user != null) {
            username = 
            <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end",alignItems:"flex-end"}}>
                <h4><Badge variant={"primary"} >👤{this.state.user.username}</Badge></h4>
                <Button variant="link" onClick={this.onClickLogout} style={{fontFamily:"monospace",marginTop:-17}}>Logout</Button>
            
            </div>;
        }
        return(
            <div align={"right"}>
                {username}
            </div>

        );
    }
}

export default withRouter(Nav);
