import axios from "axios";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Notifications from "../../utils/notifications";

export default class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      startDate: "2021-06-27",
      endDate: this.initializeDateState(this.props.created),
      downloading: false,
      locale: {
        format: "DD/MM/YYYY",
        separator: " to ",
      },
    };
  }

  initializeDateState = (date) => {
    const month =
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const formatted = date.getFullYear() + "-" + month + "-" + date.getDate();
    return formatted;
  };
  onChangeDate = (event, picker) => {
    const temp1 = picker.startDate._d;
    const temp2 = picker.endDate._d;
    const month1 =
      temp1.getMonth() < 9 ? `0${temp1.getMonth() + 1}` : temp1.getMonth() + 1;
    const month2 =
      temp2.getMonth() < 9 ? `0${temp2.getMonth() + 1}` : temp2.getMonth() + 1;
    const date = temp1.getDate() < 10 ? `0${temp1.getDate()}` : temp1.getDate();
    const start = temp1.getFullYear() + "-" + month1 + "-" + date;
    const end = temp2.getFullYear() + "-" + month2 + "-" + temp2.getDate();

    this.setState({ startDate: start, endDate: end });
    console.log(start);
  };

  extractFormatFromDate = (date) => {
    //console.log(date)
    const month =
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const formated = date.getDate() + "/" + month + "/" + date.getFullYear();

    return formated;
  };

  handleCallback = (start, end, label) => {
    console.log(start);
  };

  onDownload = () => {
    const authToken = localStorage.getItem("token");
    this.setState({ downloading: true });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/campaigns/api/v1/${this.props.id}/generate_report?date=${this.state.startDate}`,
        {
          headers: { Authorization: "Bearer " + authToken },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        //console.log(url)
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.state.startDate}_reportJTI.csv`);
        document.body.appendChild(link);
        link.click();
        console.log(response.data);
        this.setState({ downloading: false, showModal: false });
      })
      .catch((err) => {
        this.setState({ downloading: false, showModal: false });
        Notifications.error("Something went wrong !");
      });
  };

  render() {
    return (
      <div>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => this.setState({ showModal: true })}
        >
          Daily Report
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header
            style={{
              borderBottom: "none",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h6 style={{ fontWeight: 200, color: "#007bff" }}>
              Report Download
            </h6>
            <h5 style={{ fontWeight: 400 }}>{this.props.id}</h5>
          </Modal.Header>
          <Modal.Body style={{ marginTop: -20 }}>
            <h4>Select a Date </h4>
            <p>For a single day report , apply same date for start and end</p>
            <DateRangePicker
              onCallback={this.handleCallback}
              onApply={this.onChangeDate}
              initialSettings={{
                singleDatePicker: true,
                startDate: "27/6/2021",
                opens: "center",
                locale: this.state.locale,
                minDate: "27/6/2021",
                maxDate: this.extractFormatFromDate(new Date()),
                autoUpdateInput: true,
                autoApply: true,
              }}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showModal: false })}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.onDownload}>
              {this.state.downloading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Download
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

}
