import React from "react";
import Notifications from "../../utils/notifications";
import authenticationService from "../services";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { withRouter } from 'react-router-dom'
import { findAllByDisplayValue } from "@testing-library/dom";

class Login extends React.Component{

    constructor() {
        super();
        this.state = {
            username: "",
            password: "",
            logging:false
        }
    }

    onchangeUsername = (e) => {
        this.setState({
            username: e.target.value
        });
    }
    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    login = (data) => {
        this.setState({logging:true})
        authenticationService.login(data)
            .then(
                (response) => {
                    localStorage.setItem("token", response.data.access);
                    this.setState({logging:false})
                    this.props.history.push("/");
                },
                (err) =>{
                    this.setState({logging:false})
                    Notifications.error("Log In Failed !");
                    this.setState({
                        username: "",
                        password: ""
                    });
                }
            )
    }

    onsubmit = (e) => {
        e.preventDefault();
        if(!this.state.username) {
            Notifications.error("Username is required");
            return;
        }
        if(!this.state.password) {
            Notifications.error("Password is required");
            return;
        }
        this.login({username:this.state.username,password:this.state.password});
    }

    render() {
        return(
            <form onSubmit={this.onsubmit}>
                <div className="row col-6 offset-3">
                    <div className="form-group col-12">
                        <input type={"text"} value={this.state.username} onChange={this.onchangeUsername} className={"form-control"} placeholder={"Username"}/>
                    </div>
                    <div className="form-group col-12">
                        <input type={"password"} value={this.state.password} onChange={this.onChangePassword} className={"form-control"} placeholder={"Password"}/>
                    </div>
                    <div className={"form-group col-12"}>
                        <button type={"submit"} className={"btn btn-primary"}>
                        {this.state.logging && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                        />}
                            Login</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default withRouter(Login);