import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Login from "./modules/authentication/components/Login";
import {NotificationContainer} from "react-notifications";
import Nav from "./modules/common/components/Nav";
import routes from "./routes";

ReactDOM.render(
    <div>
        <NotificationContainer/>
        <React.StrictMode>
          <div style={{"marginTop": "2%"}} className={"container container-fluid"}>
            <BrowserRouter>
              <Nav/>
              <Switch>
                  {routes.map(({component, path}, index) => {
                      return <Route path={path} key={'route-' + index} exact component={component}/>;
                  })}
              </Switch>
            </BrowserRouter>
          </div>
        </React.StrictMode>
    </div>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
