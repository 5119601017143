import React from "react";
import Button from 'react-bootstrap/Button'

class Question extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            question: this.props.question,
            optionsList: [
                {name: 'Text', value: 'text'},
                {name: 'Multiple Choice', value: 'multiple'},
                {name: 'Single Choice', value: 'single'},
            ]
        }
    }

    onChangeQuestionName = (event) => {
        this.setState({
            question: {
                ...this.state.question, name: event.target.value
            }
        }, () => {
            this.props.onChangeState(this.state.question, this.props.index);
        });
    }

    onChangeQuestionType = (event) => {
        this.setState({
            question: {
                ...this.state.question, type: event.target.value
            }
        }, () => {
            this.props.onChangeState(this.state.question, this.props.index);
        });

    }

    onChangeSuggestions = (event) => {
        let options = event.target.value.trim().split(",");
        options = options.map(x => x.trim());
        this.setState({
            question: {
                ...this.state.question, options: options
            }
        }, () => {
            this.props.onChangeState(this.state.question, this.props.index);
        });
    }



    render() {
        let suggestionComponent;
        if(this.state.question.type !== "" && this.state.question.type !== 'text') {
            suggestionComponent = (
                <div className={"col-12 form-group"}>
                        <input type={"text"} className={"form-control"} onChange={this.onChangeSuggestions} placeholder={"Suggestions"}/>
                        <p style={{fontSize:14,color:"grey",marginLeft:2}}>Split different options with commas ( , )</p>
                    </div>
            )
        }
        return(
            <div className={"card form-group"}>
                <Button variant="outline-danger" size="sm" onClick={()=>this.props.onRemoveQuestion(this.props.index)} style={{position:"absolute",zIndex:5,right:10,top:10,borderRadius:5}} >
                ✕    
                </Button>
                <div className={"row card-body"}>
                    <div className={"col-12 form-group"}>
                        <h5>Question #{this.props.index+1}</h5>
                    </div>
                    <div className={"col-12 form-group"}>
                        <input className={"form-control"} value={this.props.question.name} onChange={this.onChangeQuestionName} placeholder={"Question name"}/>
                    </div>
                    <div className={"col-12 form-group"}>
                        <select className={"form-control"} value={this.props.question.type} onChange={this.onChangeQuestionType} placeholder={"Question Type"}>
                            <option value="">Select Question Type</option>
                            {this.state.optionsList.map(({name, value}, index) => {
                                return <option key={'index-'+this.props.index + '-option-' + index} value={value}>{name}</option>;
                            })}
                        </select>
                    </div>
                    {suggestionComponent}
                </div>
            </div>
        );
    }
}

export default Question;